<template>
  <div>
    <Header />
      <transition name="router-animate">
        <router-view />
      </transition>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Base',
  components: {
    Header,
    Footer
  },
  metaInfo: {
    // Children can override the title.
    title: 'Evozi | Create What\'s Next',
    titleTemplate: '%s - Evozi'
  }
}
</script>

<style lang="css" type="text/css">
  /* put here just temporary not permanent */
  /* sooner or later will extract out each css out become single scss */
  @import "../styles/main.css";
  @import "../styles/animate.css";
  /* @import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"; */
</style>
