<template>
  <div class="header-site">
    <header class="header">
      <div class="container">
        <div class="header__content">
          <router-link to="/">
            <div class="header__logo"></div>
          </router-link>
          <ul class="primary-nav">
            <li class="primary-nav__item">
              <span class="primary-nav__link link-products">
                Products
              </span>
              <div class="products">
                <a href="https://play.google.com/store/apps/details?id=com.evozi.injector" target="_blank" class="products__link" style="cursor: -webkit-grab; cursor: grab;">
                  <div class="product">
                    <div class="product__icon"></div>
                    <div>
                      <h6 class="product__name">HTTP Injector</h6>
                      <p>Virtual Private Network app</p>
                    </div>
                  </div>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.evozi.network" target="_blank" class="products__link" style="cursor: -webkit-grab; cursor: grab;">
                  <div class="product">
                    <div class="product__icon product__icon--triage"></div>
                    <div>
                      <h6 class="product__name">Network Speed</h6>
                      <p>Network information monitoring app</p>
                    </div>
                  </div>
                </a>
                <a href="https://apps.evozi.com/apk-downloader/" target="_blank" class="products__link" style="cursor: -webkit-grab; cursor: grab;">
                  <div class="product">
                    <div class="product__icon product__icon--api"></div>
                    <div>
                      <h6 class="product__name">APK Downloader</h6>
                      <p>Fetch APK files from Play Store</p>
                    </div>
                  </div>
                </a>
              </div>
            </li>
            <li class="primary-nav__item">
              <router-link to="/careers" class="primary-nav__link" exact-active-class="primary-nav__link--active">
                Careers
              </router-link>
            </li>
            <li>
              <router-link to="/contact" class="primary-nav__link" exact-active-class="primary-nav__link--active">
                Contact
              </router-link>
            </li>
          </ul>
          <a class="mobile-menu" @click="toggleMobileNav">
            <span class="mobile-menu__icon"></span>
          </a>
          <div class="mobile-nav" v-bind:class="{ 'mobile-nav--is-open': mobileNavIsOpen }">
            <button class="mobile-close-button" @click="toggleMobileNav"></button>
            <div class="mobile-products">
              <p class="subtitle">Products</p>
              <a href="https://play.google.com/store/apps/details?id=com.evozi.injector" target="_blank" class="mobile-products__link">
                <div class="mobile-product">
                  <div class="product__icon"></div>
                  <h6 class="mobile-product__name">HTTP Injector</h6>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.evozi.network" target="_blank" class="mobile-products__link">
                <div class="mobile-product">
                  <div class="product__icon product__icon--triage"></div>
                  <h6 class="mobile-product__name">Network Speed</h6>
                </div>
              </a>
              <a href="https://apps.evozi.com/apk-downloader/" target="_blank" class="mobile-products__link">
                <div class="mobile-product">
                  <div class="product__icon product__icon--api"></div>
                  <h6 class="mobile-product__name">APK Downloader</h6>
                </div>
              </a>
            </div>
            <div class="mobile-links">
              <div class="mobile-links__column">
                <p class="subtitle mobile-links__subtitle">
                  Company
                </p>
                <router-link class="mobile-link" to="/" @click.native="toggleMobileNav">About</router-link>
                <a href="https://blog.evozi.com/" class="mobile-link" target="_blank" @click.native="toggleMobileNav">Blog</a>
                <router-link class="mobile-link" to="/careers" @click.native="toggleMobileNav">Careers</router-link>
                <router-link class="mobile-link" to="/contact" @click.native="toggleMobileNav">Contact</router-link>
              </div>
                <div class="mobile-links__column">
                  <p class="subtitle mobile-links__subtitle">Resources</p>
                  <a class="mobile-link" href="https://account.evozi.com"> Account Portal </a>
                  <a class="mobile-link" @click="openSoon('')"> Documentation </a>
                  <a class="mobile-link" href="https://www.evozi.com/terms.php"> Privacy & Terms </a>
                  <a class="mobile-link" href="https://status.evozi.com/"> System Status </a>
                </div>
            </div>
            <div class="mobile-call-to-action">
              <a @click="openSoon('')" class="button full-width">
              Sign Up Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      mobileNavIsOpen: false,
      url: ''
    }
  },
  methods: {
    toggleMobileNav () {
      this.mobileNavIsOpen = !this.mobileNavIsOpen
    },
    openSoon (url) {
      if (url !== '') {
        window.open (url, '_blank')
      } else {
        alert('coming soon ❤')
        this.mobileNavIsOpen = !this.mobileNavIsOpen
        return false
      }
    }
  }
}
</script>

<style>

</style>
