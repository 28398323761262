<template>
  <div class="footer-site">
    <footer v-bind:class="{ 'footer--gray': isGrayFooter(this.$route.name) }">
      <div class="container">
        <div class="footer__top">
          <div class="footer-nav">
            <div class="footer-nav__column">
              <p class="subtitle footer-nav__subtitle">Products</p>
              <a @click="openExternal('https://play.google.com/store/apps/details?id=com.evozi.injector')" class="footer-nav__link" style="cursor: pointer;">HTTP Injector</a>
              <a @click="openExternal('https://play.google.com/store/apps/details?id=com.evozi.network')" class="footer-nav__link" style="cursor: pointer;">Network Speed</a>
              <a @click="openExternal('https://apps.evozi.com/apk-downloader/')" class="footer-nav__link" style="cursor: pointer;">APK Downloader</a>
            </div>
            <div class="footer-nav__column">
              <p class="subtitle footer-nav__subtitle">Company</p>
              <router-link class="footer-nav__link" to="/" @click="openSoon('')">About</router-link>
              <router-link class="footer-nav__link" to="/careers">Careers</router-link>
              <router-link class="footer-nav__link" to="/contact">Contact</router-link>
            </div>
            <div class="footer-nav__column">
              <p class="subtitle footer-nav__subtitle">Resources</p>
              <a @click="openSoon('')" class="footer-nav__link" style="cursor: pointer;">Account portal</a>
              <a @click="openSoon('')" class="footer-nav__link" style="cursor: pointer;">Documentation</a>
              <a @click="openSoon('')" class="footer-nav__link" style="cursor: pointer;">Privacy & Terms</a>
              <a @click="openSoon('')" class="footer-nav__link" style="cursor: pointer;">System Status</a>
            </div>
          </div>
          <div class="footer__social-media">
            <a href="https://www.facebook.com/evozi/" target="_blank" class="footer__social-media-icon facebook" style="cursor: pointer;"></a>
            <a href="https://twitter.com/evozi" target="_blank" class="footer__social-media-icon twitter" style="cursor: pointer;"></a>
            <a href="https://www.github.com/evozi" target="_blank" class="footer__social-media-icon github" style="cursor: pointer;"></a>
          </div>
        </div>
        <div class="footer__bottom">
            &copy; Evozi {{ new Date().getFullYear() }}. All Right Reserved. ❤
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  },

  methods: {
    isGrayFooter (routeName) {
      if (routeName === 'Contact' || routeName === 'Careers' || routeName === 'PageNotFound' ) {
        return true
      } else {
        return false
      }
    },
    openSoon (url) {
      if (url !== '') {
        window.open(url, '_blank')
      } else {
        alert('coming soon ❤')
        return false
      }
    },
    openExternal (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
</style>
